<template>
    <div>
        <v-card class="rounded-lg" elevation="0">
            <v-tabs fixed-tabs background-color="#50c78f" dark>
                <v-tabs-slider color="#b1e3cc"></v-tabs-slider>
                <v-tab>ACOMPANHAR EVOLUÇÃO</v-tab>
  
                <v-tab-item>
                    <template>
                        <p class="space-personalized"></p>
                        <v-card class="mx-auto" width="800" prepend-icon="mdi-home">
                            <v-card-text>
                                Paciente {{ name }}, <br> CPF: {{ cpf }} <br> Diagnóstico: {{ initial_diagnosis }}
                            </v-card-text>
                        </v-card>
                        <p class="space-personalized"></p>
                    </template>

                    <br>
                    <template>
                        <v-data-table
                            :headers="headers"
                            :items="glasgow"
                            :items-per-page="10"
                            class="elevation-1"
                        >
                        </v-data-table>
                      </template>

                      <br>

                      
                      <p class="space-personalized"></p>
                      <v-container>
                        <div class="buttons">
                            <v-row>
                                <v-btn class="rounded-lg" elevation="0" color="#40a374"  @click="$router.push('/doador')"  v-if="activateButtons == true">
                                    <span class="textBtn">Concluir Cadastro</span>
                                </v-btn>
                                <!-- <p class="space-personalized"></p>
                                <v-btn class="rounded-lg" elevation="0" color="#faae20"  @click="$router.push('/sorologia')">
                                    <span class="textBtn">Acompanhar Evolução</span>
                                </v-btn> -->
                                <p class="space-personalized"></p>
                                <v-btn class="rounded-lg" elevation="0" color="grey"  @click="$router.push('/busca-ativa')">
                                    <span class="textBtn">Sair</span>
                                </v-btn>
                            </v-row>
                        </div>
                      </v-container>
                </v-tab-item>
            </v-tabs>
        </v-card>
        <v-snackbar absolute background-color="white" color="#40a374" v-model="snackbar">
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                Fechar
                </v-btn>
            </template>
        </v-snackbar>
    </div>
  </template>
  
  <script>
  import axios from "@/axios";
  export default {
    data() {
        return {
            headers: [
            {
              text: "Responsável pela análise",
              value: "user",
              align: "center",
            },
            {
              text: "Escala de Glasgow",
              value: "description",
              align: "center",
            },
            {
              text: "Data",
              value: "created_at",
              align: "center",
            }
            ],
            dialog: false,
            disabledDonorForm: false,
            disableScaleForm: true,
            activateButtons: false,
            displayError: {
                display: false,
                message: [],
            },
            activeSearch:{},
            glasgow: [],
            donor_id: "",
            donorIdSaved: "",
            donorId:"",
            newScales: false,
            newDonor: true,
            loading: true,
            options: {},
            donor: [],
            status_serology: [],
            message: "",
            snackbar: false,
            messageError: "",
            name: "",
            cpf: "",
            initial_diagnosis: "",
            rg:"",
        };
    },
  
    created() {
        this.activeSearch={...this.$route.query.item}
          this.name = this.activeSearch.name
          this.cpf = this.activeSearch.cpf
          this.initial_diagnosis = this.activeSearch.initial_diagnosis

        this.donorId = this.activeSearch.id
        axios.get("/glasgow_scale").then((response) => {
            this.glasgow = response.data.data;
            this.glasgow = this.glasgow.filter((x) => x.donor_id == this.donorId);
        });
    },
  
    watch: {
        options: {
            handler() {
                this.indoDataTable();
            },
        },
        deep: true,
    },
  
    methods: {
        indoDataTable() {
            this.loading = true;
        },
    },
  
    mounted() {
        this.indoDataTable();
    },
  
  };
  </script>
  
  <style scoped>
  .header-table {
    display: flex;
    margin-bottom: 20px;
  }
  
  p{
    font-size: 16px;
    /* font-weight: bold; */
  }
  .d-flex {
    padding: 0 12px;
  }
  
  .space {
    width: 5px;
  }
  
  .space-personalized {
    padding: 4px;
  }
  
  .spacediferent {
    width: 7px;
  }
  
  .v-btn:not(.v-btn--round).v-size--default {
    height: 40px !important;
  }
  
  .textBtn {
    font-family: "Poppins";
    color: #ffffff;
  }
  
  .create-user-dialog {
    padding: 20px;
  }
  
  .card {
    padding: 35px;
  }
  
  .banner {
    background: #b3ffab;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right,
            #27ae60,
            #1abc9c);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right,
            #ee106d,
            #e46987);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
    border-radius: 8px;
  
    margin-bottom: 20px;
  
    display: flex;
    align-items: center;
  }
  
  .icon {}
  
  .banner>h4 {
    color: #ffffff;
    padding: 10px;
  }
  
  .cardFooter {
    padding: 0 !important;
  }
  </style>
  